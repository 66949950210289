import {
  CountryResponse,
  CurrentLocalization,
  Localization,
} from '@/types/localization';
import { PartialItem } from '@directus/sdk';
import { fetchCountries } from '@/utils/country';

export async function getLocalizations(
  preview = false,
): Promise<Array<Localization>> {
  const data = await fetchCountries({
    status: preview ? undefined : { _eq: 'published' },
  });

  if (!data) return [];
  return buildCountriesLocalizations(data);
}

export function buildCountriesLocalizations(
  countryItems: Array<PartialItem<CountryResponse>>,
): Array<Localization> {
  return countryItems.reduce<Array<Localization>>((acc, cur) => {
    const localizations = buildCountryLocalizations(cur);
    return [...acc, ...localizations];
  }, []);
}

export function buildCountryLocalizations(
  countryItem: PartialItem<CountryResponse>,
): Array<Localization> {
  const hasLanguages =
    countryItem.languages && countryItem.languages.length > 0;

  if (!countryItem.code || !countryItem.default_language) return [];

  if (!hasLanguages) {
    return [
      {
        country: countryItem.code,
        language: countryItem.default_language,
        defaultLanguage: countryItem.default_language,
        label:
          buildLabel(countryItem, countryItem.default_language) ||
          countryItem.code.toUpperCase(),
      },
    ];
  }

  return countryItem.languages!.reduce<Array<Localization>>((acc, cur) => {
    const label = buildLabel(countryItem, cur?.languages_code);
    return countryItem.code && cur?.languages_code && label
      ? [
          ...acc,
          {
            country: countryItem.code,
            language: cur.languages_code,
            defaultLanguage: countryItem.default_language!,
            label,
          },
        ]
      : acc;
  }, []);
}

export function buildLabel(
  countryItem: PartialItem<CountryResponse>,
  language: string | undefined,
  withoutLanguageCode = false,
): string | undefined {
  const labelLanguage = language || countryItem.default_language;
  if (!labelLanguage) return undefined;

  const selectedTranslation = countryItem.translations?.find(
    t => t?.languages_code === labelLanguage,
  );

  const defaultTranslation = countryItem.translations?.find(
    t => t?.languages_code === countryItem.default_language,
  );

  const enTranslation = countryItem.translations?.find(
    t => t?.languages_code === 'en',
  );

  let translation;
  if (selectedTranslation?.name) {
    translation = selectedTranslation;
  } else if (defaultTranslation?.name) {
    translation = defaultTranslation;
  } else if (enTranslation?.name) {
    translation = enTranslation;
  }

  const name = translation?.name;
  if (!name) return countryItem.code?.toUpperCase();

  const code = translation?.languages_code?.toUpperCase();

  if (!labelLanguage || !code || withoutLanguageCode) {
    return name;
  } else {
    return `${name} (${code})`;
  }
}

export function localizationToString(
  loc: CurrentLocalization,
): string | undefined {
  return loc.language && loc.country
    ? `${loc.language}/${loc.country}`
    : undefined;
}

export function localizationFromString(
  s: string,
): CurrentLocalization | undefined {
  if (/\w+\/\w+/.test(s)) {
    const [language, country] = s.split('/');
    return { country, language };
  }

  return undefined;
}

export function getDefaultLanguage(
  country: string,
  localizations: Array<Localization>,
): string {
  const localization = localizations.find(loc => loc.country === country);
  if (!localization) throw new Error(`Country not found: ${country}`);

  const defaultLanguage = localization.defaultLanguage;
  if (!defaultLanguage)
    throw new Error(`No default language found for country ${country}`);

  return defaultLanguage;
}
