export const Logo = ({ color }: { color?: string }) => (
  <svg
    width='90'
    height='17'
    viewBox='0 0 90 17'
    fill={color || 'none'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M3.62029 5.72689H9.12874C9.25748 5.72689 9.36281 5.62163 9.36281 5.49298V0.732914C9.36281 0.475613 9.57347 0.265095 9.83095 0.265095H12.2809C12.5384 0.265095 12.749 0.475613 12.749 0.732914V13.9878C12.749 14.2451 12.5384 14.4556 12.2809 14.4556H9.83095C9.57347 14.4556 9.36281 14.2451 9.36281 13.9878V9.09129C9.36281 8.96264 9.25748 8.85738 9.12874 8.85738H3.62029C3.49155 8.85738 3.38622 8.96264 3.38622 9.09129V13.9839C3.38622 14.2412 3.17555 14.4517 2.91808 14.4517H0.468141C0.210663 14.4517 0 14.2412 0 13.9839V0.729016C0 0.471715 0.210663 0.261196 0.468141 0.261196H2.91808C3.17555 0.261196 3.38622 0.471715 3.38622 0.729016V5.48908C3.38622 5.62163 3.49155 5.72689 3.62029 5.72689Z' />
    <path d='M73.3693 3.65679L71.1925 4.90431C70.9896 5.02126 70.7321 4.97448 70.5917 4.78735C69.8505 3.78934 68.7698 3.24355 67.4395 3.24355C65.1964 3.24355 63.5345 4.9277 63.5345 7.36036C63.5345 9.99574 65.3485 11.4772 67.4513 11.4772C68.7464 11.4772 69.831 10.9431 70.6268 9.95676C70.779 9.76573 71.0559 9.73454 71.2549 9.87489L73.2757 11.3056C73.4981 11.4616 73.5371 11.7696 73.3693 11.984C72.0507 13.6837 70.0065 14.7207 67.4825 14.7207C63.1014 14.7207 60.0078 11.6604 60.0078 7.36036C60.0039 3.06032 63.0975 0 67.4825 0C70.0026 0 72.2497 1.09548 73.5254 2.98235C73.6775 3.20846 73.6073 3.52034 73.3693 3.65679Z' />
    <path d='M57.8231 3.40731H54.3277C54.199 3.40731 54.0936 3.51257 54.0936 3.64122V13.9878C54.0936 14.2451 53.883 14.4556 53.6255 14.4556H51.1755C50.9181 14.4556 50.7074 14.2451 50.7074 13.9878V3.64122C50.7074 3.51257 50.6021 3.40731 50.4733 3.40731H46.9662C46.7087 3.40731 46.498 3.19679 46.498 2.93949V0.732941C46.498 0.47564 46.7087 0.265121 46.9662 0.265121H57.827C58.0845 0.265121 58.2952 0.47564 58.2952 0.732941V2.93949C58.2913 3.19679 58.0806 3.40731 57.8231 3.40731Z' />
    <path d='M27.6632 14.4556H17.723C17.4655 14.4556 17.2549 14.2451 17.2549 13.9878V0.732941C17.2549 0.47564 17.4655 0.265121 17.723 0.265121H27.6203C27.8778 0.265121 28.0884 0.47564 28.0884 0.732941V2.84593C28.0884 3.10323 27.8778 3.31375 27.6203 3.31375H20.8791C20.7503 3.31375 20.645 3.419 20.645 3.54765V5.53199C20.645 5.66064 20.7503 5.7659 20.8791 5.7659H27.1873C27.4447 5.7659 27.6554 5.97642 27.6554 6.23372V8.33891C27.6554 8.59621 27.4447 8.80673 27.1873 8.80673H20.8791C20.7503 8.80673 20.645 8.91199 20.645 9.04064V11.1692C20.645 11.2979 20.7503 11.4031 20.8791 11.4031H27.6632C27.9207 11.4031 28.1313 11.6136 28.1313 11.8709V13.9878C28.1313 14.2451 27.9207 14.4556 27.6632 14.4556Z' />
    <path d='M42.9479 14.4556H33.0116C32.7541 14.4556 32.5435 14.2451 32.5435 13.9878V0.732941C32.5435 0.47564 32.7541 0.265121 33.0116 0.265121H42.9089C43.1663 0.265121 43.377 0.47564 43.377 0.732941V2.84593C43.377 3.10323 43.1663 3.31375 42.9089 3.31375H36.1637C36.035 3.31375 35.9297 3.419 35.9297 3.54765V5.53199C35.9297 5.66064 36.035 5.7659 36.1637 5.7659H42.4719C42.7294 5.7659 42.9401 5.97642 42.9401 6.23372V8.33891C42.9401 8.59621 42.7294 8.80673 42.4719 8.80673H36.1637C36.035 8.80673 35.9297 8.91199 35.9297 9.04064V11.1692C35.9297 11.2979 36.035 11.4031 36.1637 11.4031H42.9479C43.2054 11.4031 43.416 11.6136 43.416 11.8709V13.9878C43.416 14.2451 43.2054 14.4556 42.9479 14.4556Z' />
    <path d='M80.4186 5.71904H85.9232C86.0519 5.71904 86.1572 5.61378 86.1572 5.48513V0.721169C86.1572 0.463868 86.3679 0.253349 86.6254 0.253349H89.0753C89.3328 0.253349 89.5435 0.463868 89.5435 0.721169V8.38171C89.5435 8.63901 89.3328 8.84953 89.0753 8.84953H86.1572H80.4186C80.2899 8.84953 80.1846 8.95479 80.1846 9.08344V13.9761C80.1846 14.2334 79.9739 14.4439 79.7164 14.4439H77.2665C77.009 14.4439 76.7983 14.2334 76.7983 13.9761V0.721169C76.7983 0.463868 77.009 0.253349 77.2665 0.253349H79.7164C79.9739 0.253349 80.1846 0.463868 80.1846 0.721169V5.48123C80.1846 5.61378 80.2899 5.71904 80.4186 5.71904Z' />
    <path d='M90.0002 14.4245C90.0002 15.6018 88.9976 16.6154 87.839 16.6154C86.6725 16.6154 85.6582 15.6018 85.6582 14.4245C85.6582 13.2705 86.6725 12.2764 87.839 12.2764C88.9976 12.2764 90.0002 13.2705 90.0002 14.4245Z' />
  </svg>
);
