import Polyglot from 'node-polyglot';
import React, { useCallback, useMemo } from 'react';
import { TranslationContext, TranslationKey } from './TranslationContext';

export type Translations = Record<TranslationKey, string>;

type TranslationProviderProps = {
  translations: Translations;
  currentLanguage: string;
  children: React.ReactNode;
};

interface InterpolationOptions extends Polyglot.InterpolationTokenOptions {
  silent?: boolean;
  defaultValue?: string;
}

/**
 * A provider to expose:
 * - t - Polyglot translation function - e.g. t('passenger.title')
 */
export const TranslationProvider = ({
  translations,
  currentLanguage,
  children,
}: TranslationProviderProps) => {
  const onMissingKey = useCallback((key: string) => {
    throw new Error(`Missing translation for '${key}'`);
  }, []);

  // Set up and cache the polyglot library
  const polyglot = useMemo(
    () =>
      new Polyglot({
        phrases: translations || {},
        locale: currentLanguage,
        onMissingKey,
        allowMissing: false,
      }),
    [currentLanguage, translations, onMissingKey],
  );

  // Provide the polyglot translation function
  const t = useMemo(() => {
    return function (
      phrase: TranslationKey,
      options?: number | InterpolationOptions,
    ): string {
      if (
        !polyglot.has(phrase) &&
        typeof options === 'object' &&
        (options.silent || options.defaultValue)
      ) {
        return options.defaultValue !== undefined
          ? options.defaultValue
          : phrase;
      }

      return polyglot.t(phrase, options);
    };
  }, [polyglot]);

  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};
