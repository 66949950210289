import { Directus, ITransport, TypeMap } from '@directus/sdk';
import { AxiosInstance } from 'axios';

export interface TransportWithAxios extends ITransport {
  axios: AxiosInstance;
}

export async function getDirectusClient<T extends TypeMap>() {
  if (!process.env.READER_USER_TOKEN)
    throw new Error('No static token defined');

  if (!process.env.DIRECTUS_API_URL)
    throw new Error(
      `Unable to create Directus client using URL: ${process.env.DIRECTUS_API_URL}`,
    );

  const directus = new Directus<T>(process.env.DIRECTUS_API_URL);

  // On the server-side, intercept calls and report metrics to Datadog
  if (typeof window === 'undefined') {
    const transport = directus.transport as TransportWithAxios;
    const { Metrics, http } = await import('../galaxy-node');
    http.measureRequests({
      client: transport.axios,
      destination: 'help-center-directus',
      metrics: new Metrics('help-center-portal'),
    });
  }

  await directus.auth.static(process.env.READER_USER_TOKEN);

  return directus;
}
