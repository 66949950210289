import {
  TranslationKey,
  useTranslation,
} from '@/context/translation/TranslationContext';
import { useMemo } from 'react';
import NextHead from 'next/head';

export const Head = (props: {
  title?: string;
  titlePrefix?: TranslationKey;
  article?: boolean;
}) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    let title_ = t('meta-title');

    if (!!props.title) {
      title_ = props.titlePrefix
        ? `${t(props.titlePrefix)} ${props.title.toLowerCase()}`
        : props.title;
    }

    return `Heetch - ${title_}`;
  }, [t, props.title, props.titlePrefix]);

  return (
    <NextHead>
      <link rel='icon' href='/favicon.ico' />
      <title>{title}</title>
      <meta content={t('meta-description')} name='description' />
      <meta content='/images/meta_tag_logo.png' property='og:image' />
      <meta content={title} property='og:title' />
      <meta content={t('meta-description')} name='og:description' />
      <meta content='https://support.heetch.com' property='og:url' />
      {props.article ? (
        <meta content='article' property='og:type' />
      ) : (
        <meta content='website' property='og:type' />
      )}
      <meta content={title} property='twitter:title' />
      <meta content={t('meta-description')} property='twitter:description' />
      <meta content='/images/meta_tag_logo.png' property='twitter:image' />
      <meta content='summary' property='twitter:card' />
      <meta
        name='google-site-verification'
        content='dnZCdRIAxjMhnmyuRv2mrcFjYshgI07TSKr_toOKiQo'
      />
    </NextHead>
  );
};
