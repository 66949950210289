import { CurrentLocalization } from '@/types/localization';
import { Category } from '@/types/category';

export const pathnames = {
  root: '/[country]/[language]',
  category: '/[country]/[language]/[category]',
  section: '/[country]/[language]/[category]/[section]',
  article: '/[country]/[language]/[category]/[section]/[article]',
  form: '/[country]/[language]/[category]/form/[formid]',
  success: '/[country]/[language]/[category]/form/success',
};

export function extractLocalization(
  path: string,
): CurrentLocalization | undefined {
  const re = /^\/([a-zA-Z]+)\/([a-zA-Z]+)/;
  const matches = path.match(re);
  if (!matches) return undefined;

  const [, country, language] = matches;

  return {
    country,
    language,
  };
}

export function extractCategory(
  path: string,
  language: string,
  country: string,
  categories: Category[],
): string | undefined {
  const re = new RegExp(`^/${country}/${language}/(\\w+)`);
  const matches = path.match(re);
  if (!matches) return undefined;

  const [, category] = matches;

  if (categories.some(({ id }) => id === category)) return category;

  return undefined;
}

export function replaceLocalization(
  path: string,
  language: string,
  defaultLanguage: string,
  country: string,
  sectionSlugs?: Record<string, string>,
  articleSlugs?: Record<string, string>,
): string {
  const re = /^\/([a-zA-Z]+)\/([a-zA-Z]+)/;
  const newPath = path.replace(re, `/${country}/${language}`);

  const segments = newPath.split('/').filter(s => !!s);

  if (segments.length > 3 && sectionSlugs) {
    const sectionSlug = segments[3];
    if (Object.values(sectionSlugs).some(s => s === sectionSlug)) {
      const newSectionSlug =
        sectionSlugs[language] ||
        sectionSlugs[defaultLanguage] ||
        sectionSlugs['en'];

      if (newSectionSlug) {
        segments[3] = newSectionSlug;
      }
    }
  }

  if (segments.length > 4 && articleSlugs) {
    const articleSlug = segments[4];
    if (Object.values(articleSlugs).some(s => s === articleSlug)) {
      const newArticleSlug =
        articleSlugs[language] ||
        articleSlugs[defaultLanguage] ||
        articleSlugs['en'];

      if (newArticleSlug) {
        segments[4] = newArticleSlug;
      }
    }
  }

  return '/' + segments.join('/');
}
