import { theme as flamingo } from '@heetch/flamingo-react';
import { BrandingProperties, LayoutTheme } from '@/types/layout';

export const theme = {
  media: {
    mobile: `@media (max-width: 479px)`,
    mobileUp: `@media (max-width: 549px)`,
    tabletDown: `@media (max-width: 859px)`,
    tablet: `@media (min-width: 480px) and (max-width: 991px)`,
    desktop: `@media (min-width: 992px)`,
  },
  flamingo,
};
export const defaultTheme = (driverTheme: boolean): LayoutTheme => {
  return {
    dark: getBrandDark({ driverTheme }),

    light: {
      light1: getBrandLight({ driverTheme }),
      light2: getBrandLight2({ driverTheme }),
    },
    uiPrimary: getBrandUiPrimary({ driverTheme }),
  };
};

export function getBrandDark({
  driverTheme,
}: Pick<BrandingProperties, 'driverTheme'>): string {
  return driverTheme
    ? theme.flamingo.color_v3.type.driver.title
    : theme.flamingo.color_v3.bg.passenger;
}

export function getBrandUiPrimary({
  driverTheme,
}: Pick<BrandingProperties, 'driverTheme'>): string {
  return driverTheme
    ? theme.flamingo.color_v3.driver.ui.primary
    : theme.flamingo.color_v3.passenger.ui.primary;
}
export function getBrandLight({
  driverTheme,
}: Pick<BrandingProperties, 'driverTheme'>): string {
  return driverTheme
    ? theme.flamingo.color_v3.brand.navy.shade2
    : theme.flamingo.color_v3.brand.pink.shade2;
}
export function getBrandLight2({
  driverTheme,
}: Pick<BrandingProperties, 'driverTheme'>): string {
  return driverTheme
    ? theme.flamingo.color_v3.brand.navy.shade3
    : theme.flamingo.color_v3.type.driver.highlight;
}

export function getBrandDark2({
  driverTheme,
}: Pick<BrandingProperties, 'driverTheme'>): string {
  return driverTheme
    ? theme.flamingo.color_v3.driver.type.title
    : theme.flamingo.color_v3.passenger.type.section;
}
