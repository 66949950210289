import { BrandingProperties } from '@/types/layout';
import styled, { css, useTheme } from 'styled-components';
import { Logo } from '@/components/Logo';
import { theme } from '@styles/theme';
import Link from 'next/link';
import { UrlObject } from 'url';
import { useTranslation } from '@/context/translation/TranslationContext';

const HeaderWrapper = styled.div<BrandingProperties>`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;

  padding: 48px 70px 40px;

  ${theme.media.mobile} {
    padding: 28px 16px;
  }

  ${props =>
    props.invertedTheme
      ? css`
          background-color: ${props.theme.light.light1};
        `
      : css`
          background-color: white;
          box-shadow: 0px 1px 5px ${theme.flamingo.color.element.inactive};
        `}
`;

type HeaderProps = BrandingProperties & { homeUrl: UrlObject };

export const Header = ({ invertedTheme, homeUrl }: HeaderProps) => {
  const { dark } = useTheme();
  const { t } = useTranslation();
  return (
    <HeaderWrapper invertedTheme={invertedTheme}>
      <Link href={homeUrl} aria-label={t('home-page')}>
        <Logo color={invertedTheme ? 'white' : dark} />
      </Link>
    </HeaderWrapper>
  );
};
