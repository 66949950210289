import styled from 'styled-components';
import countryCodeToFlagEmoji from 'country-code-to-flag-emoji';
import { Select, UiText } from '@heetch/flamingo-react';
import { theme } from '@styles/theme';
import { CurrentLocalization, LocalizationInfo } from '@/types/localization';
import { useCallback, useMemo } from 'react';
import {
  getDefaultLanguage,
  localizationFromString,
  localizationToString,
} from '@/utils/localization';
import { useTranslation } from '@/context/translation/TranslationContext';

const FooterWrapper = styled.div`
  background-color: white;
  box-shadow: 0px -1px 5px ${theme.flamingo.color.element.inactive};

  display: flex;

  padding: 0 70px;
  align-items: center;
  justify-content: space-between;

  min-height: 136px;

  ${theme.media.tabletDown} {
    // height: 200px;
    padding: 0 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, max-content);
    grid-column-gap: 24px;
    grid-template-rows: repeat(2, 1fr);
    justify-items: start;
    justify-content: center;
  }

  > .f-FormEl-wrapper {
    min-width: 0;
  }
`;

const LocalizationSelector = styled(Select)`
  min-width: 0;
  border-bottom: none !important;
  ${theme.media.tabletDown} {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  ${theme.media.mobileUp} {
    order: 1;
  }
`;

const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  ${theme.media.tabletDown} {
    grid-row: 2 / 2;
    grid-column: 1 / span 2;
    justify-self: stretch;
    justify-content: center;
  }
  ${theme.media.mobileUp} {
    order: 3;
  }
  img {
    display: block;
    max-height: 40px;
    ${theme.media.tabletDown} {
      max-height: 45px;
    }
    width: auto;
    height: auto;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  a {
    color: ${theme.flamingo.color.text.primary};
  }

  ${theme.media.mobileUp} {
    order: 2;
  }
`;

export type FooterProps = {
  onSelectLocalization: (
    loc: CurrentLocalization | undefined,
    defaultLanguage?: string,
  ) => void;
  localization: LocalizationInfo;
};

export const Footer = ({
  onSelectLocalization,
  localization: { localizations, country, language },
}: FooterProps) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      (localizations || []).map(loc => ({
        label: `${countryCodeToFlagEmoji(loc.country)} ${loc.label}`,
        value: localizationToString(loc),
      })),
    [localizations],
  );

  const value = useMemo(
    () => localizationToString({ country, language }),
    [country, language],
  );

  const handleSelectLocalization = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const loc = localizationFromString(e.target?.value);
      let defaultLn;
      try {
        defaultLn = loc
          ? getDefaultLanguage(loc.country, localizations)
          : undefined;
      } catch (_) {
        // ignore
      }

      onSelectLocalization(loc, defaultLn);
    },
    [localizations, onSelectLocalization],
  );

  return (
    <FooterWrapper>
      <LocalizationSelector
        id='select-localization'
        aria-label={t('select-localization')}
        data-testid='select-localization'
        defaultValue={value}
        options={options}
        onChange={handleSelectLocalization}
      />

      <Badges>
        <a
          href={process.env.APP_STORE_LINK}
          target='_blank'
          rel='noreferrer'
          data-testid='link_app_store'
        >
          <img src='/images/app_store.png' alt='Download on App Store' />
        </a>
        <a
          href={process.env.GOOGLE_PLAY_LINK}
          target='_blank'
          rel='noreferrer'
          data-testid='link_google_play'
        >
          <img src='/images/google_play.png' alt='Get it on Google Play' />
        </a>
      </Badges>

      <Links>
        <a
          href={process.env.TERMS_LINK}
          target='_blank'
          rel='noreferrer'
          data-testid='link_terms'
        >
          <UiText variant='contentBold'>{t('terms')}</UiText>
        </a>
        <a
          href={process.env.PRIVACY_LINK}
          target='_blank'
          rel='noreferrer'
          data-testid='link_privacy'
        >
          <UiText variant='contentBold'>{t('privacy')}</UiText>
        </a>
      </Links>
    </FooterWrapper>
  );
};
