import { createContext, useContext } from 'react';
import Polyglot from 'node-polyglot';
import en from '@/locales/en.json';

export type TranslationKey = keyof typeof en;

export type TranslationContextValue = {
  t(
    phrase: TranslationKey,
    options?: number | Polyglot.InterpolationOptions,
  ): string;
};

export const TranslationContext = createContext<TranslationContextValue>({
  t: p => p,
});

export const useTranslation = (): TranslationContextValue => {
  const instance = useContext(TranslationContext);
  if (!instance) {
    throw new Error('There was an error getting i18n instance from context');
  }
  return instance;
};
