import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import { BrandingProperties, LayoutTheme } from '@/types/layout';
import { Header } from '@/components/Header';
import styled, { css, ThemeProvider } from 'styled-components';
import { defaultTheme, theme } from '@styles/theme';
import { Footer } from '@/components/Footer';
import { CurrentLocalization, LocalizationInfo } from '@/types/localization';
import {
  TranslationProvider,
  Translations,
} from '@/context/translation/TranslationProvider';
import { pathnames, replaceLocalization } from '@/utils/routing';
import { TranslationKey } from '@/context/translation/TranslationContext';
import { Head } from '@/components/Head';

const LayoutWrapper = styled.div`
  overflow: auto;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
`;

const Content = styled.div<BrandingProperties>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 70px;

  ${props =>
    props.invertedTheme
      ? css`
          background-color: ${props.theme.light.light1};
          color: ${theme.flamingo.color_v3.type.reverse};
        `
      : css`
          color: ${props.theme.dark};
        `}

  ${theme.media.mobile} {
    padding: 64px 16px 48px;
  }
`;

type LayoutProps = BrandingProperties & {
  title?: string;
  titlePrefix?: TranslationKey;
  localization: LocalizationInfo;
  translations: Translations;
  countryIndependent?: boolean;
  onSelectLocalization?: SelectLocalizationFn;
  customTheme?: LayoutTheme;
  whiteHeader?: boolean;
  article?: boolean;
  sectionSlugs?: Record<string, string>;
  articleSlugs?: Record<string, string>;
};

type SelectLocalizationFn = (
  loc: CurrentLocalization | undefined,
  defaultLanguage?: string,
) => void;

export const Layout = ({
  title,
  titlePrefix,
  invertedTheme = false,
  driverTheme = false,
  localization,
  translations,
  countryIndependent = false,
  children,
  customTheme,
  whiteHeader,
  article,
  onSelectLocalization,
  sectionSlugs,
  articleSlugs,
}: PropsWithChildren<LayoutProps>) => {
  const { asPath } = useRouter();
  const selectLocalization = useMemo<SelectLocalizationFn>(() => {
    if (onSelectLocalization) return onSelectLocalization;
    return (loc: CurrentLocalization | undefined, defaultLanguage) => {
      if (!loc) return;
      if (loc.country !== localization.country && !countryIndependent) {
        location.assign(`/${loc.country}/${loc.language}`);
      } else {
        location.assign(
          replaceLocalization(
            asPath,
            loc.language,
            defaultLanguage || loc.language,
            loc.country,
            sectionSlugs,
            articleSlugs,
          ),
        );
      }
    };
  }, [
    onSelectLocalization,
    localization.country,
    countryIndependent,
    asPath,
    sectionSlugs,
    articleSlugs,
  ]);

  const homeUrl = {
    pathname: pathnames.root,
    query: {
      language: localization.language,
      country: localization.country,
    },
  };

  return (
    <TranslationProvider
      translations={translations}
      currentLanguage={localization.language}
    >
      <Head title={title} titlePrefix={titlePrefix} article={article} />

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore This is a bug in @types/styled-components, see https://github.com/styled-components/styled-components/issues/3731 */}
      <ThemeProvider theme={customTheme || defaultTheme(driverTheme)}>
        <LayoutWrapper>
          <Header
            invertedTheme={invertedTheme && whiteHeader ? false : invertedTheme}
            homeUrl={homeUrl}
            data-testid='layout-header'
          />
          <Content invertedTheme={invertedTheme} data-testid='layout-content'>
            {children}
          </Content>
          <Footer
            onSelectLocalization={selectLocalization}
            localization={localization}
            data-testid='layout-footer'
          />
        </LayoutWrapper>
      </ThemeProvider>
    </TranslationProvider>
  );
};
