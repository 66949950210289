import {
  ContextResponse,
  Country,
  CountryResponse,
  FullCountry,
} from '@/types/localization';
import { getDirectusClient } from './directus';
import { buildLabel } from './localization';
import { Filter, PartialItem } from '@directus/sdk';

function extractCountriesFromContext(
  contextData: PartialItem<ContextResponse>[],
): string[] {
  const countryCodes: string[] = [];

  for (const context of contextData) {
    const contextCountries = context?.countries || [];
    for (const contextCountry of contextCountries) {
      if (contextCountry?.country_code !== undefined) {
        countryCodes.push(contextCountry.country_code);
      }
    }
  }

  return countryCodes;
}

export async function fetchCountries(filter: Filter<CountryResponse>) {
  const directus = await getDirectusClient<{
    country: CountryResponse;
    context: ContextResponse;
  }>();

  const { data: contextData } = await directus.items('context').readByQuery({
    fields: ['name', 'countries.country_code'],
    filter: {
      name: { _eq: 'help_center' },
    },
  });

  const countriesInContext = contextData
    ? extractCountriesFromContext(contextData)
    : [];

  if (countriesInContext.length === 0) return [];

  const { data } = await directus.items('country').readByQuery({
    fields: [
      'code',
      'status',
      'default_language',
      'languages.languages_code',
      'translations.languages_code',
      'translations.name',
    ],
    filter: { ...filter, code: { _in: countriesInContext } },
  });

  return data;
}

export async function getCountries(preview = false): Promise<Country[]> {
  const data = await fetchCountries({
    status: preview ? undefined : { _eq: 'published' },
  });

  if (!data) return [];

  return data.reduce<Country[]>((acc, country) => {
    const label = buildLabel(country, undefined, true);
    if (!country?.code || !label) return acc;

    return [...acc, { code: country.code, name: label }];
  }, []);
}

export async function getCountry(countryCode: string): Promise<FullCountry> {
  const data = await fetchCountries({
    code: { _eq: countryCode },
  });

  if (!data?.[0]) throw new Error('Unable to find country: ' + countryCode);

  const country = data[0];

  const label = buildLabel(country, undefined, true);
  if (!country.code || !label)
    throw new Error('Unable to find country: ' + countryCode);

  return {
    code: country.code,
    name: label,
    default_language: country.default_language!,
    languages: country.languages?.reduce<string[]>(
      (acc, cur) => (cur?.languages_code ? [...acc, cur.languages_code] : acc),
      [],
    ) || [country.default_language!],
  };
}
